<template>
    <div>
      <v-autocomplete
      v-model="model"
      :items="items"
      item-text="title"
      item-value="id"
      :label="$t('vvtList.filterColumn.title')"
      hide-details
      :loading="loading"
      :disabled="loading"
      clearable
      full-width
      @click:clear="$emit('click-clear')"
      no-filter
      solo
      single-line
      dense
      :flat="flat"
      :background-color="model ? 'amber lighten-5' : ''"
    >
      <template v-slot:prepend-inner>
        <v-icon color="primary">mdi-filter-variant</v-icon><span v-if="!loading && model && prependTitle" class="subtitle-2 ml-1 mt-0 primary--text" style="padding-top:2px;" v-text="$t('vvtList.filterColumn.title') + ':'" />
      </template>
      <template v-slot:selection="{ on, item }">
        <v-tooltip :disabled="item.title.length <= 35 && !item.description" bottom color="primary" v-on="on">
          <template v-slot:activator="{ on: onToolTip }">
            <div v-on="onToolTip" class="text-truncate">
              <span class="body-2">{{ item.title }}</span>
            </div>
          </template>
          {{ item.title }}
          <template v-if="item.description"><br />{{ item.description }}</template>
        </v-tooltip>
      </template>
      <template v-slot:item="{item, on}">
        <v-tooltip :disabled="!item.description" bottom color="primary" v-on="on">
          <template v-slot:activator="{ on: onToolTip }">
            <v-list-item v-on="{...onToolTip}">
                {{ item.title }}
                <v-spacer></v-spacer>
                <v-avatar size="28" color="grey lighten-3">
                  <v-icon v-if="item.icon" small color="primary">{{ item.icon }}</v-icon>
                  <span v-else class="primary--text text-subtitle-2">{{ item.title.charAt(0) }}</span>
                </v-avatar>
            </v-list-item>
          </template>
          {{ item.description }}
        </v-tooltip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
const LEA_VVT_LIST_FILTER = 'LEA_VVT_LIST_FILTER';
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'VvtFilter',
    props: {
        selected: {
          type: [Number, String],
          default: null
        },
        prependTitle: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        }
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/List/en.json'),
            de: require('@/locales/vvt/List/de.json'),
        },
    },
    beforeCreate () {
        this.$wait.start('fetch filter items');
    },
    mounted () {
      this.fetchFilter().then((e) => {
        let storageFilter = localStorage.getItem(LEA_VVT_LIST_FILTER);
        if( e && e.data && !e.data.find(x => x.id === parseInt(storageFilter)) ) {
          localStorage.removeItem(LEA_VVT_LIST_FILTER);
        }
        this.$wait.end('fetch filter items');
      })
    },
    computed: {
        ...mapGetters({
            filterItems: 'filter/getAllFilter'
        }),
        loading () {
          return this.$wait.is('fetch filter items');
        },
        aliasItem () {
            return this.$route && this.$route.query && this.$route.query.filterAlias ? [{
                        id: -1,
                        title: this.$t('globalFilterAlias.' + this.$route.query.filterAlias)
                  }] : [];
        },
        justItems () {
          return this.aliasItem.concat(this.filterItems);
        },
        items () {
            return this.justItems.map(x => ({
                ...x,
                header: this.$t(x.header)
            }))
        },
        selectedFilter() {
            return this.items.find(x => x.id === this.model) || null;
        },
        selectedFilterTitle() {
            if (this.selectedFilter) {
                return this.selectedFilter.title || null;
            }
            return null;
        },
        selectedFilterDescription() {
            if (this.selectedFilter) {
                return this.selectedFilter.description || null;
            }
            return null;
        },
        model: {
            get() {
              let selectBy = !this.aliasItem.length ? this.selected : -1;
                if (selectBy === 'all' || !this.justItems.find(x => x.id === selectBy)) return false;
                return selectBy;
            },
            set(val) {
                this.$emit('select', val);
            },
        },
    },
    methods: {
      ...mapActions({
            fetchFilter: 'filter/fetch'
      })
    }
};
</script>
