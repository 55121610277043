var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{attrs:{"items":_vm.items,"item-text":"title","item-value":"id","label":_vm.$t('vvtList.filterColumn.title'),"hide-details":"","loading":_vm.loading,"disabled":_vm.loading,"clearable":"","full-width":"","no-filter":"","solo":"","single-line":"","dense":"","flat":_vm.flat,"background-color":_vm.model ? 'amber lighten-5' : ''},on:{"click:clear":function($event){return _vm.$emit('click-clear')}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-filter-variant")]),(!_vm.loading && _vm.model && _vm.prependTitle)?_c('span',{staticClass:"subtitle-2 ml-1 mt-0 primary--text",staticStyle:{"padding-top":"2px"},domProps:{"textContent":_vm._s(_vm.$t('vvtList.filterColumn.title') + ':')}}):_vm._e()]},proxy:true},{key:"selection",fn:function(ref){
var on = ref.on;
var item = ref.item;
return [_c('v-tooltip',_vm._g({attrs:{"disabled":item.title.length <= 35 && !item.description,"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onToolTip = ref.on;
return [_c('div',_vm._g({staticClass:"text-truncate"},onToolTip),[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(item.title))])])]}}],null,true)},on),[_vm._v(" "+_vm._s(item.title)+" "),(item.description)?[_c('br'),_vm._v(_vm._s(item.description))]:_vm._e()],2)]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-tooltip',_vm._g({attrs:{"disabled":!item.description,"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onToolTip = ref.on;
return [_c('v-list-item',_vm._g({},Object.assign({}, onToolTip)),[_vm._v(" "+_vm._s(item.title)+" "),_c('v-spacer'),_c('v-avatar',{attrs:{"size":"28","color":"grey lighten-3"}},[(item.icon)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(item.icon))]):_c('span',{staticClass:"primary--text text-subtitle-2"},[_vm._v(_vm._s(item.title.charAt(0)))])],1)],1)]}}],null,true)},on),[_vm._v(" "+_vm._s(item.description)+" ")])]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }